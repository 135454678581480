/**
 * Creates a SHA256 hash of the given |string|, the result of which will be
 * 64 characters in length. This is done client side as we don't want to
 * transmit unhashed (sensitive) data at all.
 */
export async function SHA256Hash(string: string): Promise<string> {
  const buffer = new TextEncoder().encode(string);
  const hashedBuffer = await crypto.subtle.digest('SHA-256', buffer);

  return [...new Uint8Array(hashedBuffer)]
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}
