import { z } from 'zod';

/**
 * Interface definition for the SignInPassword API, exposed through
 * /api/auth/sign-in/password.
 */
export const signInPasswordDefinition = z.object({
  request: z.object({
    /**
     * The email whom is attempting to sign in to their account.
     */
    email: z.string().email(),

    /**
     * The password associated with that account, SHA256 hashed.
     */
    password: z.string().length(64),
  }),

  response: z.strictObject({
    /**
     * Whether the sign in attempt was successful.
     */
    success: z.boolean(),

    /**
     * Token that signals that a password update must take place. This will
     * usually be issued when the user signed in with an access token as
     * opposed to a password.
     */
    requiredPasswordUpdateToken: z.string().optional(),
  }),
});

export type SignInPasswordDefinition = z.infer<typeof signInPasswordDefinition>;
