/**
 * Password rules as they will be indicated to the password field, so that
 * password managers can automatically create a password that meets our
 * requirements.
 */
const passwordRules =
  'required: upper; required: lower; required: digit; ' +
  'minlength: 8; allowed: [-().&@?\'#,/&quot;+]; max-consecutive: 2';

type CheckEntry = {
  /**
   * The element that shows the failed state of a check
   * @type {HTMLElement}
   */
  elemFailure: HTMLElement,

  /**
   * The element that shows the success state of a check
   * @type {HTMLElement}
   */
  elemSuccess: HTMLElement,

  /**
   * Is this check required for the overall state?
   * @type {boolean}
   */
  required: boolean,

  /**
   * The function that performs the check
   * @type {Function}
   */
  validator: (arg0: string) => boolean,
}

export class PasswordChecks {
  constructor(passwordElement: HTMLInputElement, checksElement: HTMLElement) {
    this.#checksElement = checksElement;
    this.#passwordElement = passwordElement;

    this.#options = {
      minLength: 8,
      sum: 25,
    }

    this.#checks = {
      length: {
        required: true,
        validator: this.#validateLength.bind(this),
        ...this.#buildCheck('Minimaal ' + this.#options.minLength + ' karakters lang'),
      },
      number: {
        required: true,
        validator: this.#validateNumber.bind(this),
        ...this.#buildCheck('Bevat tenminste één getal'),
      },
      sum: {
        required: false,
        validator: this.#validateSum.bind(this),
        ...this.#buildCheck('Getallen tellen op tot ' + this.#options.sum),
      },
      casing: {
        required: true,
        validator: this.#validateCasing.bind(this),
        ...this.#buildCheck('Bevat zowel hoofdletters als kleine letters'),
      },
    }

    this.#runChecks('');

    passwordElement.addEventListener('input', this.onInput.bind(this));
    passwordElement.setAttribute('passwordRules', passwordRules);
  }

  async onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    this.#runChecks(input.value);
  }

  // Privates

  #buildCheck(message: string) {
    const
      elemFailure = this.#buildIconElement('xmark', 'danger'),
      elemSuccess = this.#buildIconElement('check', 'success'),
      li = document.createElement('li'),
      text = document.createTextNode(message)
    ;

    li.appendChild(elemFailure);
    li.appendChild(elemSuccess);
    li.appendChild(text);

    this.#checksElement.appendChild(li);

    return { elemFailure, elemSuccess };
  }

  #buildIconElement(iconCircleType: string, textColorType: string) {
    const
      icon = document.createElement('i'),
      span = document.createElement('span')
    ;

    icon.classList.add(
      'pe-2',
      'fa',
      'fa-circle-' + iconCircleType,
      'text-' + textColorType,
    );

    span.appendChild(icon);

    return span;
  }

  #runChecks(value: string) {
    let allValid = true;

    for (const [, check] of Object.entries(this.#checks)) {
      const valid = check.validator(value);

      if (check.required && !valid) {
        allValid = false;
      }

      check.elemFailure.hidden = valid;
      check.elemSuccess.hidden = !valid;
    }

    if (allValid) {
      this.#passwordElement.setCustomValidity('');
    } else {
      this.#passwordElement.setCustomValidity(
        'Je wachtwoord moet ' +
        'minimaal ' + this.#options.minLength + ' karakters lang zijn, ' +
        'minimaal één nummer bevatten, ' +
        'minimaal één kleine letter bevatten en ' +
        'minimaal één hoofdletter bevatten'
      );
    }
  }

  /**
   * Validates that the given `value` contains at least one uppercase and one
   * lowercase character.
   */
  #validateCasing(value: string): boolean {
    return /[a-z]/.test(value) &&
           /[A-Z]/.test(value);
  }

  /**
   * Validates that the given `value` is at least the minimal number of
   * characters set in the options.
   */
  #validateLength(value: string): boolean {
    return value.length >= /* minimum length: */ this.#options.minLength;
  }

  /**
   * Validates that the given `value` contains at least one number.
   */
  #validateNumber(value: string): boolean {
    return /[0-9]/.test(value);
  }

  /**
   * Validates that the given `value` contains numbers that add up to required
   * sum set in the options.
   */
  #validateSum(value: string): boolean {
    const numbers = [ ...value.matchAll(/\d/g) ];

    let total = 0;
    for (const number of numbers) {
      total += parseInt(number[0]);
    }

    return total === this.#options.sum;
  }

  /**
   * Each element for a check
   */
  #checks: {
    length: CheckEntry,
    number: CheckEntry,
    sum: CheckEntry,
    casing: CheckEntry,
  }

  /**
   * The list where the checks will be shown
   * @type {Element}
   */
  #checksElement: Element;

  /**
   * Configurable options for the password checks
   */
  #options: {
    /**
     * Minimal length of the password
     * @type {number}
     */
    minLength: number,
    /**
     * The sum of the numbers in the password
     * @type {number}
     */
    sum: number,
  }

  /**
   * The input element where the user is typing a password
   * @type {Element}
   */
  #passwordElement: HTMLInputElement;
}
