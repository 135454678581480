import { z } from 'zod';

/**
 * Interface definition for the SignIn API using passkey, exposed through
 * /api/auth/sign-in/passkey.
 */
export const signInPasskeyDefinition = z.object({
  request: z.object({
    /**
     * The email whom is attempting to sign in to their account.
     */
    email: z.string().email(),

    /**
     * The verification that was generated by their authenticator.
     */
    verification: z.any(),
  }),

  response: z.strictObject({
    /**
     * Whether the sign in attempt was successful.
     */
    success: z.boolean(),

    /**
     * Optional detailed information on what went wrong in the action.
     */
    error: z.string().optional(),
  }),
});

export type SignInPasskeyDefinition = z.infer<typeof signInPasskeyDefinition>;
