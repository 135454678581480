import { Collapse, Modal } from 'bootstrap';
import * as WebAuthn from '@github/webauthn-json';

import { PasswordChecks } from './passwordChecks';
import { issueServerAction } from '../lib/issueServerAction';
import { SHA256Hash } from '../lib/sha256';

import { ConfirmIdentityDefinition } from '../lib/api/auth/confirmIdentity';
import { SignInPasskeyDefinition } from '../lib/api/auth/signInPasskey';
import { SignInPasswordDefinition } from '../lib/api/auth/signInPassword';
import { SignInPasswordUpdateDefinition } from '../lib/api/auth/signInPasswordUpdate';
import { SignUpAllowedDefinition } from '../lib/api/auth/signUpAllowed';
import { SignUpDefinition } from '../lib/api/auth/signUp';

/**
 * Progress in the authentication flow, influenced by the user's actions.
 */
type AuthenticationFlowState =
  // (1) Default state: The user has to enter their email.
  'email' |

  // (2a) Existing user with the given username, but no passkey credentials.
  'signInPassword' | 'signInPasswordUpdate' |

  // (2b) Existing user with the given username, but the user has lost their
  //      credentials.
  'lostPassword' | //'lostPasswordReset' | 'lostPasswordComplete' |

  // (2c) Existing user with the given username, but the account has not been
  //      activated yet (or has been deactivated).
  'activationInformation' |

  // (2d) There does not exist a user with the given username.
  'signUpAllowed' | 'signUp' | 'signUpComplete'
;

class AuthenticationFlow {
  constructor() {
    this.#chip = document.getElementById('auth-chip')!;

    const modal =
      this.#modal = document.getElementById('auth-dialogs-modal')!;
    this.#modalBs = new Modal(modal);

    this.#dialogs = {
      email: modal.querySelector('.dialog-email')!,
      signInPassword: modal.querySelector('.dialog-sign-in-password')!,
      signInPasswordUpdate: modal.querySelector('.dialog-sign-in-password-update')!,
      lostPassword: modal.querySelector('.dialog-lost-password')!,
      activationInformation: modal.querySelector('.dialog-activation-information')!,
      signUpAllowed: modal.querySelector('.dialog-sign-up-allowed')!,
      signUp: modal.querySelector('.dialog-sign-up')!,
      signUpComplete: modal.querySelector('.dialog-sign-up-complete')!,
    };

    // TODO: Gather from params
    // const passwordResetToken = null;
    // this.#flowState = passwordResetToken ? 'lostPasswordReset'
    //                                      : 'email';
    this.#flowState = 'email';

    // Bind events to their callbacks
    this.#bindings();
  }

  onChipClicked(event?: Event, showModal = true) {
    // Hide all other dialogs
    for (const [, dialog] of Object.entries(this.#dialogs)) {
      dialog.hidden = true;
    }

    // Unhide the dialog matching the auth flow state
    this.#dialogs[this.#flowState].hidden = false;
    this.#setInputFocus();

    // Show the modal
    if (showModal) {
      this.#modalBs.show();
    }
  }

  onLostPassword(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.#setFlowState('lostPassword');
  }

  onModalClose() {
    this.#flowState = 'email';
  }

  onModalShown() {
    this.#setInputFocus();
  }

  // email

  async onSubmitEmail(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    // Grab the e-mail address from the form
    const
      form = event.target as HTMLFormElement,
      input = form.querySelector('input[name="email"]') as HTMLInputElement,
      email = input.value
    ;

    const response = await issueServerAction<ConfirmIdentityDefinition>(
      '/api/auth/confirm-identity',
      { email },
    );

    // Store e-mail address for later use
    this.#email = email;

    if (response.success) {
      if (response.activated) {
        if (response.options && WebAuthn.supported()) {
          try {
            const result = await WebAuthn.get({ 'publicKey': response.options });
            const verification = await issueServerAction<SignInPasskeyDefinition>(
              '/api/auth/sign-in/passkey',
              {
                email: email,
                verification: result,
              },
            );

            if (verification.success) {
              window.location.reload();
              return;
            } else if (verification.error) {
              // eslint-disable-next-line no-console
              console.error('Unable to use a passkey:', verification.error);
            }
          } catch(error) {
            // eslint-disable-next-line no-console
            console.error('Unable to use passkey:', error);
          }
        }

        this.#setFlowState('signInPassword');
      } else {
        this.#setFlowState('activationInformation');
      }
    } else {
      this.#setFlowState('signUpAllowed');
    }
  }

  // signInPassword

  async onSubmitSignInPassword(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement;

    // Get the collapsable error and hide it (if it was shown).
    const errorElement = form.querySelector('p.error') as Element;
    errorElement.classList.remove('show');

    // Grab the plaintext password from the form
    const
      input = form.querySelector('input[name="password"]') as HTMLInputElement,
      plaintextPassword = input.value
    ;

    const response = await issueServerAction<SignInPasswordDefinition>(
      '/api/auth/sign-in/password',
      {
        email: this.#email!,
        password: await SHA256Hash(plaintextPassword),
      },
    );

    // The password is incorrect...
    if (!response.success) {
      errorElement.textContent =
        'Dit is niet het wachtwoord wat bij je account hoort. Probeer het nog eens?';
      Collapse.getOrCreateInstance(errorElement).show();
      return;
    }

    // The server can require the user to update their password, in which case
    // they will not be logged in just yet. Advance them to the update page
    // when this is the case.
    if (response.requiredPasswordUpdateToken) {
      this.#passwordUpdateToken = response.requiredPasswordUpdateToken;
      this.#setFlowState('signInPasswordUpdate');
      return;
    }

    window.location.reload();
  }

  // signInPasswordUpdate

  async onSubmitSignInPasswordUpdate(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement;

    // Get the collapsable error and hide it (if it was shown).
    const errorElement = form.querySelector('p.error') as Element;
    errorElement.classList.remove('show');

    // Grab the plaintext password from the form
    const
      input = form.querySelector('input[name="password"]') as HTMLInputElement,
      plaintextPassword = input.value
    ;

    const response = await issueServerAction<SignInPasswordUpdateDefinition>(
      '/api/auth/sign-in/update-password',
      {
        password: await SHA256Hash(plaintextPassword),
        passwordUpdateToken: this.#passwordUpdateToken!,
      },
    );

    // Could not update the password :(
    if (!response.success) {
      errorElement.textContent =
        'Je wachtwoord kan op dit moment niet aangepast worden, probeer het later nog een keer.';
      Collapse.getOrCreateInstance(errorElement).show();
      return;
    }

    window.location.reload();
  }

  // signUp

  async onSubmitSignUpAllowed(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement;

    // Get the collapsable error and hide it (if it was shown).
    const errorElement = form.querySelector('p.error') as Element;
    errorElement.classList.remove('show');

    // Grab the code from the form
    const
      input = form.querySelector('input[name="code"]') as HTMLInputElement,
      code = input.value
    ;

    const response = await issueServerAction<SignUpAllowedDefinition>(
      '/api/auth/sign-up/allowed',
      { code },
    );

    if (response.success) {
      this.#setFlowState('signUp');
    } else {
      errorElement.textContent =
        'Deze code is niet correct om je in te schrijven. Controleer de ' +
        'code en probeer het nog eens.';
      Collapse.getOrCreateInstance(errorElement).show();
      return;
    }
  }

  async onSubmitSignUp(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement;

    // Get the collapsable error and hide it (if it was shown).
    const errorElement = form.querySelector('p.error') as Element;
    errorElement.classList.remove('show');

    // Grab the field(s) from the form
    const
      inputFirstName = form.querySelector('input[name="firstName"]') as HTMLInputElement,
      firstName = inputFirstName.value,
      inputLastName = form.querySelector('input[name="lastName"]') as HTMLInputElement,
      lastName = inputLastName.value,
      inputBirthdate = form.querySelector('input[name="birthdate"]') as HTMLInputElement,
      birthdate = inputBirthdate.value,
      inputPhoneNumber = form.querySelector('input[name="phoneNumber"]') as HTMLInputElement,
      phoneNumber = inputPhoneNumber.value,
      inputPassword = form.querySelector('input[name="password"]') as HTMLInputElement,
      plaintextPassword = inputPassword.value
    ;

    const response = await issueServerAction<SignUpDefinition>(
      '/api/auth/sign-up',
      {
        firstName,
        lastName,
        birthdate,
        phoneNumber,
        email: this.#email!,
        password: await SHA256Hash(plaintextPassword),
      },
    );

    if (response.success) {
      // Set name for next dialog
      this.#dialogs.signUpComplete
        .querySelector('strong.first-name')!
        .textContent = firstName;

      this.#setFlowState('signUpComplete');
    } else {
      errorElement.textContent = response.error!;
      Collapse.getOrCreateInstance(errorElement).show();
    }
  }

  // Privates

  #bindings() {
    // Shared bindings
    this.#chip
      .addEventListener('click', this.onChipClicked.bind(this));

    this.#modal
      .addEventListener('shown.bs.modal', this.onModalShown.bind(this));
    this.#modal
      .addEventListener('hide.bs.modal', this.onModalClose.bind(this));

    // Bindings for each dialog
    this.#dialogs.email
      .addEventListener('submit', this.onSubmitEmail.bind(this));

    this.#dialogs.signInPassword
      .addEventListener('submit', this.onSubmitSignInPassword.bind(this));
    this.#dialogs.signInPassword
      .querySelector('a.auth-lost-password')!
      .addEventListener('click', this.onLostPassword.bind(this));

    this.#dialogs.signInPasswordUpdate
      .addEventListener('submit', this.onSubmitSignInPasswordUpdate.bind(this));
    new PasswordChecks(
      this.#dialogs.signInPasswordUpdate.querySelector('input[name="password"]')!,
      this.#dialogs.signInPasswordUpdate.querySelector('ul.password-checks')!,
    );

    this.#dialogs.signUpAllowed
      .addEventListener('submit', this.onSubmitSignUpAllowed.bind(this));

    this.#dialogs.signUp
      .addEventListener('submit', this.onSubmitSignUp.bind(this));
    new PasswordChecks(
      this.#dialogs.signUp.querySelector('input[name="password"]')!,
      this.#dialogs.signUp.querySelector('ul.password-checks')!,
    );
  }

  #setFlowState(state: AuthenticationFlowState) {
    this.#flowState = state;
    this.onChipClicked(undefined, false);
  }

  // Focus the first input of the active dialog if it is a form
  #setInputFocus() {
    const dialog = this.#dialogs[this.#flowState];
    if (dialog.tagName !== 'FORM') {
      return;
    }

    const input = dialog.querySelector('input:not([type="hidden"])');
    if (input) {
      (<HTMLInputElement>input).focus();
    }
  }

  /**
   * Authentication chip, this can be clicked to show a contextual dialog.
   * @type {HTMLElement}
   */
  #chip: HTMLElement;

  /**
   * Dialogs within this authentication flow.
   */
  #dialogs: {
    email: HTMLElement,
    signInPassword: HTMLElement,
    signInPasswordUpdate: HTMLElement,
    lostPassword: HTMLElement,
    activationInformation: HTMLElement,
    signUpAllowed: HTMLElement,
    signUp: HTMLElement,
    signUpComplete: HTMLElement,
  };

  /**
   * Email address of the user that is trying to log in.
   * @type {string}
   */
  #email?: string;

  /**
   * Current state of the authentication flow
   * @type AuthenticationFlowState
   */
  #flowState: AuthenticationFlowState;

  /**
   * The element containing all the dialogs
   */
  #modal: HTMLElement;

  /**
   * The Modal as provided by Bootstrap
   * @type Modal
   */
  #modalBs: Modal;

  /**
   * The token used to verify a password change.
   * @type {string}
   */
  #passwordUpdateToken?: string;
}

document.addEventListener('DOMContentLoaded', () => {
  new AuthenticationFlow();
});

document.addEventListener('turbo:render', () => {
  new AuthenticationFlow();
});
