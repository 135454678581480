import { z } from 'zod';

/**
 * Interface definition for the SignUpAllowed API exposed through
 * /api/auth/sign-up/allowed.
 */
export const signUpAllowedDefinition = z.object({
  request: z.object({
    /**
     * The code to verify
     */
    code: z.string(),
  }),

  response: z.strictObject({
    /**
     * Whether the code was correct
     */
    success: z.boolean(),
  }),
});

export type SignUpAllowedDefinition = z.infer<typeof signUpAllowedDefinition>;
