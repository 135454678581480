import { z } from 'zod';

/**
 * Interface definition for the Register API, exposed through
 * /api/auth/register.
 */
export const signUpDefinition = z.object({
  request: z.object({
    /**
     * The email address of the account that should be created.
     */
    email: z.string().email(),

    /**
     * The password associated with that account, SHA256 hashed.
     */
    password: z.string().length(64),

    /**
     * The user's first name.
     */
    firstName: z.string(),

    /**
     * The user's last name.
     */
    lastName: z.string(),

    /**
     * Date on which the user was born. (YYYY-MM-DD)
     */
    birthdate: z.string().regex(/^[1|2](\d{3})\-(\d{2})-(\d{2})$/),

    /**
     * Phone number of the user, in an undefined format.
     */
    phoneNumber: z.string(),
  }),

  response: z.strictObject({
    /**
     * Whether the sign up attempt was successful.
     */
    success: z.boolean(),

    /**
     * The error message that occurred when `success` is not set to true.
     */
    error: z.string().optional(),
  }),
});

export type SignUpDefinition = z.infer<typeof signUpDefinition>;
