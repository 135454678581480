import { z } from 'zod';

/**
 * Interface definition for the SignInPasswordUpdate API, exposed through
 * /api/auth/sign-in/password-update.
 */
export const signInPasswordUpdateDefinition = z.object({
  request: z.object({
    /**
     * The password that they would like to store for their account, SHA256 hashed.
     */
    password: z.string().length(64),

    /**
     * The password reset token that validates this update request.
     */
    passwordUpdateToken: z.string(),
  }),

  response: z.strictObject({
    /**
     * Whether the sign in attempt was successful following a password update.
     */
    success: z.boolean(),
  }),
});

export type SignInPasswordUpdateDefinition = z.infer<typeof signInPasswordUpdateDefinition>;
