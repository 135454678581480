import { z } from 'zod';

/**
 * Interface definition for the ConfirmIdentity API, exposed through
 * /api/auth/confirm-identity.
 */
export const confirmIdentityDefinition = z.object({
  request: z.object({
    /**
     * The username for whom the associated identity should be found.
     */
    email: z.string().email(),
  }),

  response: z.strictObject({
    /**
     * Whether the identify for the requested user could be found.
     */
    success: z.boolean(),

    /**
     * Whether the account associated with that identity has been activated already.
     */
    activated: z.boolean().optional(),

    /**
     * When successful, the webauthn options that can be used in the browser.
     */
    options: z.any().optional(),
  }),
});

export type ConfirmIdentityDefinition = z.infer<typeof confirmIdentityDefinition>;
