type ServerCallDefinition = {
  request: object;
  response: object;
};

type RequestOptions = {
  method: 'GET' | 'POST';
}

/**
 * Issues a call to the server at the given `endpoint` with the given `request`
 * information. When successful, will return an object conforming to the
 * response type, or otherwise throw an Error that should be caught by the user
 * interface.
 *
 * @param endpoint The endpoint to which the call should be made.
 * @param request Request information that should be included in the request.
 * @returns Response from the server, unverified but assumed to be correct for now.
 */
export async function issueServerAction<T extends ServerCallDefinition>(
  endpoint: string,
  request: T['request'],
  options: RequestOptions = { method: 'POST' },
) : Promise<T['response']> {
  try {
    const
      csrfMeta = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement,
      csrfToken = csrfMeta.content
    ;

    let body = null;
    if (options.method === 'POST') {
      body = JSON.stringify(request);
    }

    const response = await fetch(
      endpoint,
      {
        method: options.method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cookie': document.cookie,
          'X-CSRF-Token': csrfToken,
        },
        body: body,
      },
    );

    return await response.json();
  } catch (cause) {
    throw new Error(`The server ran into an issue, please try again later (${cause}).`);
  }
}
